@import '@styles/theme/theme';

.social-media {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 35px;
  margin-bottom: 35px;

  :nth-of-type(odd) {
    transform: rotate(2deg);
  }

  :nth-of-type(even) {
    transform: rotate(-4deg);
  }

  &.light {
    img {
      fill: $primary;
    }

    .link {
      background-color: $background-alt;
    }
  }

  &.dark .link {
    background-color: $secondary;

    &:hover {
      img {
        filter: brightness(1) invert(0);
      }
    }

    img {
      filter: brightness(0) invert(1);
    }
  }
}

.link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  padding: 10px;
  margin-right: 10px;

  /* align imgs to center */
  cursor: pointer;
  border-radius: 10px;
  transition: all 100ms linear;

  &:hover {
    background-color: $tertiary;
  }
}
