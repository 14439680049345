@import '@styles/theme/theme';

.root {
  padding-top: 110px;
  padding-bottom: 110px;
  color: $ai-agent-text-color;
  background-color: $ai-agent-bg;
  background-image: url('./bg-top.png'), url('./bg-btm.png');
  background-repeat: no-repeat;
  background-position:
    top center,
    bottom center;
  background-size: 100% auto;
}
