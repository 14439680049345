@import '@styles/theme/theme';

.label {
  width: fit-content;
  padding: 5px 10px;
  margin: 5px;
  font-size: 16px;
  text-transform: capitalize;
  background-color: $background;
  border-radius: 8px;

  @include media('sm') {
    font-size: 18px;
  }
}
