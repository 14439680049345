@import '@styles/theme/theme';

.header {
  position: relative;
  height: 40px;

  .info {
    position: absolute;
    left: 50%;
    width: 100%;
    height: 100%;
    margin-top: 70px;
    transform: translateX(-50%);

    p {
      margin-top: 3px;
    }

    @include media('md') {
      margin-top: 0;
    }

    @include media('ls') {
      margin-top: -7px;
    }
  }
}

.sticky {
  position: relative;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: -40px;

  @include media('ls') {
    position: sticky;
    top: 150px;
    margin-top: -50px;
  }
}
