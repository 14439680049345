@import '@styles/theme/theme';

.field {
  grid-column: 1 / 3;

  input {
    display: block;
    width: 300px;
    height: 40px;
    padding: 0 15px;
    margin-top: 10px;
    color: $primary;
    background-color: $background;
    border: 1px solid #ccc;
    border-radius: 10px;
    outline: none;

    &:hover,
    &:focus {
      border: black 1px solid;
    }
  }
}

.label {
  margin: 10px auto -5px;
  font-weight: bold;
}
