.back span {
  display: flex;
  align-items: center;
  height: 35px;
  font-size: 18px;

  .icon {
    width: 16px;
    height: 16px;
    padding: 0;
    margin-right: 10px;
    margin-left: -5px;
  }
}
