@import '@styles/theme/theme';

.container {
  @include page-container();
  @include container-padding();

  margin-top: 80px;
  margin-bottom: 100px;

  // h1 {
  //   font-size: 38px;
  // }

  @include media('md') {
    margin-top: 100px;
  }
}
