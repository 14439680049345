@import '@styles/theme/theme';

.squares-wrap {
  position: fixed;
  left: 50%;
  width: 120%;
  max-width: 1700px;
  height: fit-content;
  margin-top: 200px;
  transform: translateX(-50%);

  @media screen and (width <= 500px) {
    display: none;
  }
}

.container {
  @include page-container();
  @include container-padding();

  position: fixed;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);

  @media screen and (height <= 950px) {
    position: absolute;
    top: 0;
    left: 50%;
    margin-top: 120px;
    transform: translateX(-50%);
  }
}
