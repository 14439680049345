@import '@styles/theme/theme';

.empty-page {
  margin-top: 50px;
  margin-bottom: 50px;

  .icon {
    width: fit-content;
    margin: 0 auto 15px;

    div {
      display: block;
      width: 100%;
      margin: auto;
    }
  }

  h1,
  p {
    text-align: center;
  }

  h1 {
    margin-bottom: 25px;
  }
}

@include bp-small() {
  .empty-page {
    margin-top: 75px;
    margin-bottom: 40px;

    h1,
    p {
      width: 75%;
      margin: auto;
    }

    h1 {
      margin-bottom: 15px;
      font-size: 32px;
    }
  }
}
