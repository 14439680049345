@import '@styles/theme/theme';

.saved-jobs-banner {
  position: relative;
  box-sizing: border-box;
  padding: 25px;
  margin-top: 50px;
  margin-bottom: 50px;
  background-color: rgb(244 37 110 / 5%);
  border: 2px solid #f4256e;
  border-radius: 15px;

  .cross {
    cursor: pointer;
  }

  img {
    position: absolute;
    top: 25px;
    right: 25px;
    width: 25px;
    height: 25px;
    filter: brightness(0) !important;
  }

  h4,
  p {
    font-size: 17px;
  }

  h4 {
    width: 80%;
  }
}
