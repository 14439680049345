@import '@styles/theme/theme';

.field {
  grid-column: 1 / 3;

  input {
    display: block;
    width: 100% !important;
    height: 40px;
    padding: 0 15px;
    margin-top: 10px;
    color: $primary;
    background-color: $background;
    border: 1px solid #ccc;
    border-radius: 10px;
    outline: none;
  }
}

.disabled input {
  display: none !important;
}

.selected {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: 10px;
}

.suggestions {
  z-index: 200;
  max-height: none !important;
  margin-top: 10px;
  background: #fff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  box-shadow: 0 6px 12px #15181c1a;

  ul {
    padding-inline-start: 0;
    list-style-type: none;
  }
}

.suggestion {
  padding: 10px;
  margin: 10px;
  cursor: pointer;

  li {
    font-family: $content-font;
    font-weight: normal;
    color: $primary;
    text-align: left;
    letter-spacing: 0;
    cursor: pointer;
    border-radius: 6px;

    &:hover {
      background: $background 0% 0% no-repeat padding-box;
    }
  }
}
