@import '@styles/theme/theme';

.ai-summary-wrapper {
  margin: 20px 0;
}

.title {
  margin-bottom: 10px;
  word-break: break-word;
}

.header {
  position: relative;
  width: 100%;
  padding-bottom: 20px;
  margin-right: 20px;
  border-bottom: 1px solid rgba(32 17 80 / 20%);

  p {
    font-size: 13px; //  default size to all header p
  }
}

.employer-and-date {
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: space-between;
  margin-bottom: 12px;
  color: $primary;
  opacity: 0.6;

  @include media('sm') {
    flex-direction: row;
    gap: 10px;
    margin-bottom: 22px;
  }
}

.company,
.date-posted {
  font-size: 14px;
}

.hr {
  margin: 22px 0;
}
