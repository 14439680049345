@import '@styles/theme/theme';

.article {
  position: relative;
  display: block;
  max-width: 100%;
  height: fit-content;
  padding: 0 10px;
  margin: 0 auto;

  &:hover {
    .post-title,
    .read-more {
      text-decoration: underline;
    }
  }

  .img-container {
    position: relative;
    width: 100%;
    height: 200px;
    aspect-ratio: 3/2;
    overflow: hidden;
    background-image: var(--post-image);
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
    border-radius: 25px;

    @include media('md') {
      height: 250px;
    }

    img {
      object-fit: cover;
    }
  }

  .text {
    font-size: 16px;
    font-weight: 500;
    color: $soft-blue;

    @include media('md') {
      font-size: 18px;
    }
  }

  .excerpt {
    @extend .text;

    height: fit-content;
    font-size: 16px;
    font-weight: 500;
    color: $soft-blue;

    @include media('sm') {
      min-height: 80px;
    }

    @include media('md') {
      font-size: 18px;
    }
  }

  .post-title {
    margin: 20px 0;
    font-size: 20px;
    font-weight: 800;
    color: $primary;

    @include media('sm') {
      min-height: 100px;
    }

    @include media('md') {
      font-size: 22px;
    }
  }

  &:nth-child(1) > .photo,
  &:nth-child(3) > .photo {
    margin-top: -25px;
  }

  .read-more {
    @extend .text;

    margin-top: 20px;
    color: $blog-gray;

    @include media('md') {
      margin-top: 10px;
    }
  }
}

.stats {
  display: flex;
  width: fit-content;
  margin: 10px 0 0 -10px;
}

.loading {
  position: relative;
  display: block;
  width: 100%;
  max-width: 100%;
  height: fit-content;
  padding: 0 10px;
  margin: 0 auto;

  .img-container {
    position: relative;
    width: 100%;
    height: 200px;
    overflow: hidden;
    border-radius: 25px;

    @include skeleton();

    @include media('md') {
      height: 250px;
    }
  }

  .title {
    width: 100%;
    margin: 23px 0;

    & div {
      height: 20px;
      margin-top: 10px;
      border-radius: 4px;

      @include skeleton();

      &:nth-child(1),
      &:nth-child(2) {
        width: 100%;
      }

      &:nth-child(3) {
        width: 40%;
      }
    }
  }

  .desc {
    width: 100%;
    margin: 36px 0;

    & div {
      height: 16px;
      margin-top: 7px;
      border-radius: 4px;

      @include skeleton();

      &:nth-child(1),
      &:nth-child(2) {
        width: 100%;
      }

      &:nth-child(3) {
        width: 70%;
      }
    }
  }

  .read-more {
    @include skeleton();

    width: 100px;
    height: 20px;
    margin-top: -10px;
    border-radius: 4px;
  }
}
