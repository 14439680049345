@import '@styles/theme/theme';

.root {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 25px;
  width: 100%;
  height: 100%;
  margin-top: 40px;

  @include media('md') {
    grid-template-columns: repeat(3, 1fr);
  }

  @include media('lg') {
    grid-template-columns: repeat(4, 1fr);
  }

  .title {
    grid-column: 1 / -1;
    // font-size: 28px;
    font-weight: 500;
  }

  .link {
    position: relative;
    padding-bottom: 62.5%;
    overflow: hidden;
    border-radius: 10px;
    opacity: 1;
    transition: opacity 0.5s;

    @include media('md') {
      padding-bottom: 44%;
    }

    &:hover {
      opacity: 0.8;

      .image {
        transform: scale(1.05);
      }
    }
  }

  .image {
    z-index: 0;
    width: 100%;
    transition: transform 0.5s;
    object-fit: cover;

    @include media('md') {
      display: block;
    }
  }

  .label {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 10px;
    font-size: 18px;
    font-weight: 500;
    color: white;
    background-color: rgb(0 0 0 / 50%);
  }
}
