@import '@styles/theme/theme';

.hero {
  @include page-container();
  @include container-padding();

  margin-top: 20px;

  @include media('md') {
    margin-top: 40px;
  }

  .title {
    margin: 20px 0;
    font-family: $title-font;
    // font-size: 60px;
    font-style: normal;
    font-weight: 600;
    font-stretch: normal;
    // line-height: 70px;
    color: $secondary;

    // @include bp-medium() {
    //   font-size: 48px;
    //   line-height: 60px;
    // }

    @include bp-small() {
      margin-top: 10px;
      // font-size: 32px;
      // line-height: 40px;
    }
  }

  .results-message {
    margin-top: 10px;

    @include bp-small() {
      font-size: 16px;
    }
  }
}
