@import '@styles/theme/theme';

.content {
  position: relative;
  width: 100%;
  height: fit-content;
  min-height: 100vh;
  background-color: rgb(241 229 255);

  a {
    color: inherit;
  }
}

.hero {
  padding: 20px;
  margin-top: 40px;
  background-color: #fff;
  border-bottom-right-radius: 60px;
  border-bottom-left-radius: 60px;

  @include media('sm') {
    margin-top: 80px;
  }

  h1,
  p {
    width: 75%;
    margin: auto;
    text-align: center;
  }

  h1 {
    font-size: 32px;

    @include media('sm') {
      font-size: 46px;
    }
  }
}
