@import '@styles/theme/theme';

.content {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;

  a {
    color: inherit;
  }
}
