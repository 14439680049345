@import '@styles/theme/theme';

.wrapper {
  max-width: 280px;
  margin-left: 50px;

  @include media('md') {
    max-width: 430px;
  }
}

.cta {
  position: relative;
  left: 50%;
  width: 100%;
  max-width: 350px;
  padding: 15px 60px;
  margin: 30px auto 10px;
  font-size: 16px;
  text-align: center;
  border-radius: 25px;
  transform: translateX(-50%);
}

.disclaimer {
  text-align: center;
}

.spinner-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
