@import '@styles/theme/theme';

.card {
  @include page-container();

  display: block;
  width: 100%;
  height: fit-content;
  padding: 20px;
  margin-top: 30px;
  margin-left: unset;
  background-color: $primary;
  border-radius: 24px;

  @include media('sm') {
    padding: 30px;
  }

  @include media('ml') {
    padding: 30px;
    margin-top: 0;
    margin-left: 20px;
  }

  &:hover {
    h2 {
      text-decoration: underline;
    }
  }

  h2 {
    margin-bottom: 20px;
    line-height: 40px;
    color: white;
  }

  .desc {
    margin-bottom: 30px;
    color: white;
  }

  .image {
    position: relative;
    width: 100%;
    height: 300px;
    overflow: hidden;
    border-radius: 16px;

    img {
      object-fit: cover;
    }
  }
}
