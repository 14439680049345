@import '@styles/theme/theme';

.cta {
  position: relative;
  left: 50%;
  width: 100%;
  max-width: 350px;
  padding: 15px 60px;
  margin: 0 auto 10px;
  font-size: 16px;
  text-align: center;
  border-radius: 25px;
  transform: translateX(-50%);
}

.disclaimer {
  text-align: center;
}
