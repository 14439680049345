@import '@styles/theme/theme';

.sidebar {
  margin-bottom: 30px;

  @include media('md') {
    width: $job-page-sidebar-min-width;
    min-width: $job-page-sidebar-min-width;
    margin-left: 30px;
  }

  p:global(.h3) {
    color: $primary;
  }
}

.map {
  margin: 30px 0;

  @include media('md') {
    margin-top: 0;
  }
}

.maps-link {
  display: block;
  margin: 10px 0;
  font-size: 13px;
  text-decoration: underline;
}
