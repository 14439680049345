@import '@styles/theme/theme';

.header {
  display: block;
  margin-bottom: 20px;
  color: white;

  @include media('sm') {
    display: flex;
    justify-content: space-between;
  }

  .featured {
    width: fit-content;
    height: fit-content;
    padding: 5px 10px;
    font-size: 18px;
    color: $primary;
    background-color: $tertiary;
    border-radius: 50px;

    @include media('sm') {
      width: unset;
    }
  }

  .comments {
    width: fit-content;
    margin: 25px 0 0 -10px;

    @include media('sm') {
      width: auto;
      margin: 0 -10px 0 0;
    }
  }
}
