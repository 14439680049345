@import '@styles/theme/theme';

.title {
  margin: 0 auto;
  margin-top: 40px;
  text-align: center;
}

.flag {
  position: relative;
  left: 50%;
  margin-top: 20px;
  transform: translateX(-50%);
}

.prompt {
  margin-top: 20px;
  text-align: center;
}

.cta {
  @include reset-button();

  position: relative;
  display: block;
  padding: 15px 35px;
  margin: 30px auto 0;
  color: #000;
  text-align: center;
  background: $tertiary;
  border-radius: 50px;
  transition: filter 0.2s ease-out;

  &:focus-visible {
    outline: $focus-outline;
  }

  &:hover {
    &::after {
      position: absolute;
      top: -5px;
      left: -5px;
      width: calc(100% + 10px);
      height: calc(100% + 10px);
      content: '';
      border: 3px solid $tertiary;
      border-radius: 50px;
    }
  }
}

.outro-cta {
  position: relative;
  left: 50%;
  width: fit-content;
  margin: 20px auto;
  transform: translateX(-50%);
}

.yellow {
  position: relative;
  display: block;
  padding: 10px 25px;
  color: #000;
  text-align: center;
  background: $tertiary;
  border-radius: 25px;
  outline: none;
  transition: filter 0.2s ease-out;

  &:hover {
    &::after {
      position: absolute;
      top: -5px;
      left: -5px;
      width: calc(100% + 10px);
      height: calc(100% + 10px);
      content: '';
      border: 3px solid $tertiary;
      border-radius: 50px;
    }
  }
}
