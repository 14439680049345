@import '@styles/theme/theme';

.links {
  button {
    display: flex;
    gap: 10px;
    align-items: center;
    height: fit-content !important;
    font-size: 18px;
    text-decoration: none !important;
  }
}

.links-desktop {
  position: absolute;
  left: 50%;
  display: none;
  gap: 10px;
  justify-content: space-between;
  // width: 500px;
  height: 40px;
  padding-top: 8px;
  font-size: 18px;
  white-space: nowrap;
  transform: translateX(-50%);

  @include media('ms') {
    display: flex;
  }
}

.links-mobile {
  display: flex;
  align-items: center;
  margin-top: 5px;

  @include media('ms') {
    display: none;
  }
}
