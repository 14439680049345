@import '@styles/theme/theme';

.summary {
  margin-top: 15px;
  font-size: 18px;
  color: $soft-blue;
  text-align: center;

  @include media('md') {
    margin-top: 30px;
    font-size: 20px;
  }
}
