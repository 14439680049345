@import '@styles/theme/theme';

.apply {
  display: flex;
  flex-wrap: wrap;
  padding: 30px;
  margin-top: 50px;
  color: white;
  background-color: $primary;
  border-radius: 15px;

  a {
    color: white;
  }

  p {
    flex-basis: 100%;
    width: 100%;
  }

  .apply-btn {
    min-width: 200px;
    padding: 15px 25px;
    margin: 20px 10px 0 0;
  }
}

.reminder {
  margin-top: 20px;
}

.applied {
  display: none;

  @include media('sm') {
    display: block;
  }
}
