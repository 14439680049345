@import '@styles/theme/theme';

.sentiment-tracker {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
}

.question {
  font-size: 16px;
  font-weight: bold;

  @include media('md') {
    font-size: 19px;
  }
}

.buttons {
  display: flex;
  gap: 10px;

  @include media('md') {
    gap: 20px;
  }
}
