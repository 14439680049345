.buttons {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;

  button {
    height: fit-content;

    &:last-child {
      margin-top: 8px;
    }
  }
}
