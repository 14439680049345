@import '@styles/theme/theme';

.navbar {
  @include container-padding();

  position: fixed;
  top: 0;
  z-index: 900;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100px;
  padding-top: 30px;
  color: #fff;
  background-color: transparent;
  border-radius: 0 0 25px 25px;
  transition: all 150ms linear;

  @include bp-medium() {
    height: 60px;
    padding-top: 13px;
  }

  button img,
  a img {
    filter: brightness(0) invert(1);
  }

  a,
  button {
    color: white;
  }

  &.scrolled {
    background: #fff;
    box-shadow: $box-shadow;

    @extend .blue-elements;
  }
}

.navbar-to-blue {
  @extend .blue-elements;
}

.blue-elements {
  button img,
  a img {
    filter: unset;
  }

  div p,
  ul li a,
  a,
  button {
    color: $primary;
  }
}
