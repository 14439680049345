@import '@styles/theme/theme';

.content {
  margin-top: 30px;
  font-size: 20px;
  color: $soft-blue;

  @include media('md') {
    margin-top: 50px;
  }

  h2,
  h3 {
    color: $primary;
  }

  h2 {
    margin: 40px 0 10px;
    font-size: 25px;

    @include media('md') {
      margin: 50px 0 20px;
      font-size: 32px;
    }
  }

  h3 {
    margin: 30px 0 5px;
    font-size: 19px;

    @include media('md') {
      margin: 40px 0 10px;
      font-size: 22px;
    }
  }

  ul {
    margin-left: 30px;
    list-style: disc;

    li {
      margin: 5px 0;
    }
  }

  li,
  a,
  p,
  span {
    font-size: 18px;
    font-weight: 200;

    @include media('md') {
      font-size: 20px;
    }
  }

  a {
    color: $secondary;
    text-decoration: underline;
  }
}
