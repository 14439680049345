@import '@styles/theme/theme';

.skeleton {
  @include skeleton();
}

.map {
  height: 300px;
  border-radius: 15px;
}

.ai-summary {
  width: $ai-summary-width;
  min-height: $ai-summary-min-height;
  border-radius: $ai-summary-border-radius;
}

.loading-card {
  height: 156px;
  padding: 15px 20px;
  margin-bottom: 10px;
  background-color: white;
  border-radius: 10px;
  box-shadow: $box-shadow;

  .header {
    display: flex;
    justify-content: space-between;

    .title {
      @include skeleton();

      width: 150px;
      height: 21px;
      margin-top: 5px;
      border-radius: 4px;
    }

    .company-img {
      @include skeleton();

      width: 50px;
      height: 50px;
      border-radius: 50%;
    }
  }

  .salary {
    @include skeleton();

    width: 100px;
    height: 21px;
    margin-top: -12px;
    border-radius: 4px;
  }

  .company {
    @include skeleton();

    width: 120px;
    height: 21px;
    margin-top: 12px;
    border-radius: 4px;
  }

  .footer {
    display: flex;
    justify-content: space-between;
    height: 21px;
    margin-top: 12px;

    .location {
      display: flex;
      gap: 10px;

      div {
        @include skeleton();

        width: 80px;
        height: 21px;
        border-radius: 4px;
      }
    }

    .date {
      @include skeleton();

      width: 70px;
      height: 21px;
      border-radius: 4px;
    }
  }
}

.loading-job-extended-card {
  width: 100%;

  hr {
    border: 1px solid rgb(32 17 80 / 20%);
  }
}

.jec-breadcrumb {
  @include skeleton();

  width: 200px;
  height: 14px;
  margin: 10px 0;

  @include media('md') {
    height: 18px;
    margin-bottom: 20px;
  }
}

.jec-title {
  @include skeleton();

  width: 70%;
  height: 40px;
  margin-bottom: 10px;
  border-radius: 4px;

  @include media('md') {
    height: 60px;
  }
}

.jec-employer-and-date {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  > div {
    @include skeleton();

    width: 30%;
    height: 20px;
    border-radius: 4px;
  }
}

.jec-quick-info {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;

  > div {
    @include skeleton();

    width: 90px;
    height: 20px;
    border-radius: 4px;
  }
}

.jec-apply {
  @include skeleton();

  width: 100%;
  height: 48px;
  margin-bottom: 20px;
  border-radius: 50px;

  @include media('md') {
    width: 200px;
  }
}

.jec-description {
  @include skeleton();

  width: 100%;
  height: 1000px;
  margin: 5px 0;
  margin-top: 20px;
  border-radius: 4px;
}

.job-sidebar {
  p:global(.h3) {
    color: $primary;
  }

  @include media('md') {
    min-width: $job-page-sidebar-min-width;
    margin-left: 30px;
  }
}

.job-sidebar-location-info {
  @include skeleton();

  width: 100%;
  height: 20px;
  margin: 10px 0;
  border-radius: 4px;
}

.job-sidebar-maps-link {
  @include skeleton();

  width: 150px;
  height: 15px;
  margin: 10px 0;
  border-radius: 4px;
}

.job-sidebar-map {
  @include skeleton();

  width: 300px;
  height: 300px;
  margin-bottom: 20px;
  border-radius: 10px;
}

.pagination {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;

  .page {
    @include skeleton();

    width: 40px;
    height: 40px;
    border-radius: 12px;
  }
}
