@import '@styles/theme/theme';

.mobile-wrap {
  position: relative;
  width: 100vw;
}

.carousel {
  display: flex;
  flex-wrap: nowrap;
  height: 60px;
  padding: 0 25px 0 5px;
  margin: 0 10px 0 -10px;
  overflow: auto;

  @include media('md') {
    flex-wrap: wrap;
    height: unset;
    padding-inline: unset;
    margin: 0 0 0 -5px;
  }
}
