@import '@styles/theme/theme';

.search {
  align-items: flex-start;
  justify-content: space-between;
  height: auto;
  padding: 12px;
  background-color: $background-alt;
  background-color: #fcf1ff;
  border-radius: 16px;

  @include media('md') {
    display: flex;
  }

  .typeaheads {
    justify-content: space-between;
    width: 100%;

    @include media('sm') {
      display: flex;

      .job,
      .location {
        width: 50%;
      }
    }

    input {
      display: inline-block;
      width: 100%;
      height: 40px;
      padding: 0;
      padding-left: 45px;
      margin: 0;
      font-size: 18px;
      line-height: 1;
      color: $primary;
      white-space: normal;
      vertical-align: middle;
      background: none;
      background-color: #fff;
      background-repeat: no-repeat;
      background-position: 0 1px;
      background-size: 45px;
      border: 0;

      @include bp-small() {
        font-size: 16px;
      }
    }

    .job input {
      margin-bottom: 10px;
      background-image: url('../../../../public/images/searchbox/icon_field-search_blue.png');
      background-position: 0 -4px;
      border-radius: 10px;

      @include media('sm') {
        margin-bottom: 0;
        border-right: 1px solid #c5c5c5;
        border-radius: 10px 0 0 10px;
        outline-offset: 1;
      }
    }

    .location input {
      background-image: url('../../../../public/images/searchbox/icon_field-location-black.png');
      background-position: 0 -4px;
      border-radius: 10px;

      @include media('sm') {
        border-radius: 0 10px 10px 0;
      }
    }
  }

  button {
    position: relative;
    width: 100%;
    padding: 8px;
    margin-top: 10px;
    background-color: $primary;
    border: solid 2px $primary;

    &:hover {
      color: $primary;
      background-color: #fff;
    }

    @include media('md') {
      width: auto;
      padding: 10px 35px;
      margin-top: 0;
      margin-left: 10px;
    }
  }
}
