@import '@styles/theme/theme';

.insight {
  display: flex;
  align-items: center;
  height: 150px;
  padding: 20px;
  margin: 20px 0;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: $box-shadow;

  @include media('md') {
    height: 100px;
  }
}
