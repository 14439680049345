@import '@styles/theme/theme';

.field {
  grid-column: 1 / 3;

  input {
    display: block;
    width: 300px;
    height: 40px;
    padding: 0 15px;
    margin-top: 10px;
    color: $primary;
    background-color: $background;
    border: 1px solid #ccc;
    border-radius: 10px;
    outline: none;

    &:disabled {
      color: rgb(105 105 105);
    }
  }

  .error {
    margin-top: 10px;
    font-size: 13px;
    color: $error;
  }
}
