.title {
  margin-bottom: 10px;
  font-weight: bold;
}

.list {
  margin-left: 15px;
  list-style-type: disc;

  li {
    margin-bottom: 5px;
  }
}

.buttons {
  display: flex;
  margin-top: 10px;

  button {
    width: fit-content;
    padding: 2px 20px !important;
  }
}
