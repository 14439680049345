.btn {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;

  .icon {
    margin-top: 2px;
  }
}
