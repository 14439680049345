@import '@styles/theme/theme';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 20px 0;
}

.notice {
  position: relative;
  width: 100%;
  padding: 16px;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  background-color: rgba(#cd3ff0, 0.4);
  border-radius: 30px;

  .icon {
    display: none;
    margin-right: 10px;
    vertical-align: middle;

    @include media('sm') {
      display: inline-block;
    }
  }

  .title {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 10px;
    font-size: 28px;
    font-weight: 500;

    @include media('sm') {
      font-size: 32px;
    }
  }

  @include media('sm') {
    padding: 20px;
  }
}

.button {
  min-width: 100%;

  @include media('sm') {
    min-width: unset;
    max-width: 200px;
  }
}
