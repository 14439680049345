@import '@styles/theme/theme';

.wrap {
  .input {
    @include reset-input();

    width: 100%;
    height: 50px;
    padding: 10px;
    font-size: 20px;
    background-color: white;
    border: solid rgb(190 190 190) 2px;
    border-radius: 8px;

    &:hover {
      border: solid $primary 2px;
    }

    &:focus {
      border: solid $primary 2px;
    }

    &:focus-visible {
      outline: none;
    }
  }

  .input-error {
    border: solid $alert-chat 2px;

    &:hover,
    &:focus {
      border: solid $alert-chat 2px;
    }
  }

  .remove-number-arrows {
    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      margin: 0;
      appearance: none;
    }

    /* Firefox */
    &[type='number'] {
      appearance: textfield;
    }
  }

  .error {
    position: relative;
    margin: 0;
    font-size: 16px;
    color: $alert-chat;
  }

  button {
    position: relative;
    top: -60px;
    float: right;
    margin-bottom: -60px;
    background-color: transparent;
  }
}
