@import '@styles/theme/theme';

.job-desc {
  margin-top: 20px;
  font-size: 16px;
  line-height: 32px;

  h1 {
    font-size: 24px;
    line-height: 40px;
  }

  h2 {
    font-size: 20px;
  }

  h3 {
    font-size: 18px;
  }

  p,
  span {
    font-size: 16px;
    line-height: 32px;
  }

  a {
    color: $secondary;
    text-decoration: underline;
  }

  @include bp-small() {
    width: 100%;
    overflow: hidden;
    font-size: 15px;
    line-height: 25px;
  }

  .read-more-content {
    margin-top: 20px;
  }

  .external-site {
    width: 100%;
    overflow: auto;
    border: 0;

    @include bp-small() {
      width: 100vw;
      margin-left: -5.5%;
    }
  }

  ul {
    list-style-type: disc;

    li {
      margin-left: 20px;
    }
  }

  &--collapsed {
    max-height: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: wrap;
  }
}

.toggle-button {
  font-size: 16px;
  font-weight: 600;
  color: $primary;
  text-decoration: underline;
  cursor: pointer;
}

.job-toggle {
  display: none;

  ~ label .show-less {
    display: none;
  }

  ~ label .show-more {
    display: block;
  }

  &:checked {
    ~ label .show-less {
      display: block;
    }

    ~ label .show-more {
      display: none;
    }

    ~ .job-desc {
      max-height: unset;
    }
  }
}
