@import '@styles/theme/theme';

.common {
  z-index: 100;
  color: #fff;
  cursor: pointer;
  border-radius: 25px;
}

.search-option-wide {
  padding: 5px 15px;
  margin: 5px;
  font-size: 16px;
  border: 2px solid $primary;

  @include media('sm') {
    font-size: 18px;
  }

  &:hover {
    color: #fff;
    background: $primary;
    transition: background 0.2s ease-in-out;
  }
}

a.search-option-compact {
  display: inline-block;
  padding: 7px 10px;
  margin-top: 12px;
  margin-right: 10px;
  font-size: 13px;
  color: #fff;
  background-color: $primary;
  transition: all 100ms linear;

  &:hover {
    color: $primary;
    background-color: $tertiary;
  }
}
