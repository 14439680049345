@import '@styles/theme/theme';

.category {
  @include skeleton();

  // !important is needed because react-glider
  width: 100px !important;
  min-width: 70px !important;
  height: 45px;
  margin: 5px;
  border-radius: 8px;

  &:nth-child(1) {
    min-width: 200px !important;
  }

  &:nth-child(3) {
    min-width: 150px !important;
  }

  &:nth-child(4) {
    min-width: 70px !important;
  }

  &:nth-child(6) {
    min-width: 130px !important;
  }

  &:nth-child(5) {
    min-width: 130px !important;
  }

  &:nth-child(8) {
    min-width: 170px !important;
  }

  &:nth-child(10) {
    min-width: 200px !important;
  }
}
