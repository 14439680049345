@import '@styles/theme/theme';

.form {
  display: flex;
  width: 100%;
  margin-top: 30px;

  h3 {
    margin: 7px 20px 0 0;
    color: $primary;
  }

  button {
    margin: 0 0 0 10px;
  }
}
