@import '@styles/theme/theme';

.edit {
  z-index: 40;
  display: flex;
  gap: 15px;
  min-width: 120px;
  max-height: 45px;
  font-size: 19px;
  color: black;

  img {
    width: 20px;
    margin: auto;
  }
}
