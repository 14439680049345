@import '@styles/theme/theme';

.button {
  width: 20px !important;
  height: 20px !important;
  padding: 12px !important;
  border-radius: 100% !important;

  @include media('md') {
    width: 25px !important;
    height: 25px !important;
  }
}

.good {
  background-color: #82f1a1 !important;
}

.bad {
  background-color: #ff83a8 !important;
}
