@import '@styles/theme/theme';

.wrapper {
  @include page-container();
  @include container-padding();

  margin-top: 70px;
  margin-bottom: 100px;

  @include media('md') {
    margin-top: 120px;
  }
}

.search-wrapper {
  margin-bottom: 30px;
}

.layout {
  @include media('md') {
    display: flex;
    justify-content: space-between;
  }
}

.back {
  display: block;
  width: fit-content;
  margin-top: 20px;

  span {
    display: none;

    @include media('md') {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      cursor: pointer;
    }
  }
}
