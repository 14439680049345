@import '@styles/theme/theme';

.search-results-container {
  width: 100%;
  padding-left: 20px;
  margin-top: 50px;

  * {
    box-sizing: unset;
  }
}

@include bp-mediumLarge() {
  .search-results-container {
    width: 100%;
    padding-left: unset;
    margin-top: 50px;
  }
}

@include bp-medium() {
  .mobile-filters-button {
    display: flex;
  }

  .search-results-container {
    margin-top: 20px;
    margin-left: 0;
  }

  .search-results-sort-container {
    grid-area: 1 / 1 / 2 / 2;

    .css-iemeck-container {
      width: 100% !important;

      .css-zapfv0-control {
        width: 100% !important;
        min-height: 50px !important;
      }
    }
  }
}

@include bp-small {
  .search-results-container {
    margin-top: 10px;
  }
}

.bottom-amount {
  margin-top: 20px;

  @include media('md') {
    display: none;
  }
}
