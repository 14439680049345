@import '@styles/theme/theme';

.field:valid ~ .textarea-button-wrapper:hover {
  cursor: pointer;
  background-color: $primary;
}

.field {
  display: block;
  width: 400px;
  min-height: 54px !important;
  padding: 10px 50px 10px 10px;
  margin-top: 10px;
  font-family: $content-font;
  font-size: 15px;
  line-height: 1.5;
  color: $primary;
  resize: none;
  background-color: #fff;
  border: solid $background-alt 2px;
  border-radius: 10px;
  outline: none;

  @include bp-mediumLarge() {
    width: 250px;
  }

  @include bp-small() {
    width: 250px;
  }

  @media screen and (width <= 427px) {
    width: 100%;
  }
}

.error {
  background-color: $background-error !important;
}

.send-button {
  position: relative;
  bottom: 45px;
  box-sizing: border-box;
  float: right;
  width: 40px;
  height: 40px;
  padding: 7px;
  margin: -2px 8px 0 0;
  color: #fff;
  background-color: $secondary;
  border: none;
  border-radius: 10px;
  outline: none;
  fill: #fff;
  transition: all 100ms linear;

  &:hover {
    cursor: pointer;
    background-color: $primary;
  }
}

.send-button-disabled {
  cursor: not-allowed !important;
  background-color: $quaternary !important;
}

.open-text-message textarea:focus {
  outline: 0;
}
