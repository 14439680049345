@import '@styles/theme/theme';

.apply {
  gap: 10px;
  margin: 20px auto 0;

  @include media('sm') {
    display: flex;
  }

  button {
    // Prevent new line on small buttons
    padding-inline: 8px;
  }

  .apply-btn {
    position: relative;
    width: 100%;
    min-width: 100%;
    max-width: 200px;

    @include media('sm') {
      min-width: unset;
    }
  }

  .reminder {
    width: 100%;
    height: 50px;
    color: $primary;
    border-color: $primary;

    @include media('sm') {
      max-width: 200px;
      margin-top: -2px;
    }

    &:hover {
      color: #fff;
      background-color: $primary;
    }
  }
}
