@import '@styles/theme/theme';

.more-button {
  font-size: 18px;
  color: $soft-blue;
  text-align: left;

  @include media('md') {
    font-size: 20px;
  }
}
