@import '@styles/theme/theme';

.title {
  margin-top: 90px;
  font-size: 30px;
  line-height: 40px;
  text-align: center;

  @include media('md') {
    margin-top: 50px;
    font-size: 50px;
    line-height: 60px;
  }
}
