@import '@styles/theme/theme';

.popular-jobs {
  width: fit-content;
  max-width: 800px;

  // Prevent CLS on loading
  height: fit-content;
  min-height: 100px;
  margin: 20px auto 0;

  .compact,
  .wide {
    display: flex;
    flex-wrap: wrap;
    width: fit-content;
  }

  .wide {
    justify-content: center;
  }
}
