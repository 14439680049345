@import '@styles/theme/theme';

.grid {
  display: grid;
  grid-template-columns: repeat(1, 100%);
  gap: 50px 0;
  margin-top: 50px;

  @include media('md') {
    grid-template-columns: repeat(2, 50%);
    gap: 50px 10px;
  }

  @include media('ml') {
    grid-template-columns: repeat(3, 33%);
  }
}
