@import '@styles/theme/theme';

.button {
  @include reset-button();

  padding: 10px 25px;
  border-radius: 25px;
  outline: none;

  &:focus-visible {
    outline: $focus-outline;
  }
}

.block {
  display: block;

  &:focus-visible {
    outline: $focus-outline;
  }
}

.size {
  &-sm {
    padding: 10px 25px;
  }

  &-md {
    padding: 15px 35px;
  }

  &-lg {
    width: 100%;
    max-width: 260px;
    height: 48px;
    padding: 10px 35px;
    border-radius: 50px;
  }

  &-full {
    width: 100%;
    max-width: 100%;
  }
}

.default {
  @extend .button;

  color: #fff;
  background: $secondary;
  background-color: $secondary;

  &:disabled {
    cursor: not-allowed;
    background: $quaternary;
    background-color: $quaternary;

    &:hover,
    &:focus {
      background: $quaternary;
      background-color: $quaternary;
    }
  }
}

.secondary {
  @extend .default;

  color: $primary;
  background: transparent;
  border: 1px solid $primary;

  &:hover {
    color: #fff;
    background-color: $primary;

    img {
      filter: brightness(0) invert(1) !important;
    }
  }
}

.blue {
  @extend .default;

  display: block;
  color: #fff;
  background-color: $primary;
  border: solid $primary 2px;
  transition: 0s;

  &:hover {
    color: $primary;
    background-color: #fff;
  }

  &:disabled {
    cursor: not-allowed;
    background: rgb(32 17 80 / 20%);
    border: solid rgb(32 17 80 / 20%) 2px;

    &:hover {
      color: white;
      background: rgb(32 17 80 / 20%);
    }
  }
}

.simple-text {
  @include reset-button();

  width: 100%;
  height: 48px;
  font-weight: 400;
  text-decoration: underline;
  background-color: transparent;

  &:disabled {
    cursor: not-allowed;
  }

  &:focus-visible {
    outline: $focus-outline;
  }
}

.yellow {
  @extend .button;

  position: relative;
  color: #000;
  text-align: center;
  background: $tertiary;
  transition: filter 0.2s ease-out;

  &:hover {
    &:not(:disabled) {
      &::after {
        position: absolute;
        top: -5px;
        left: -5px;
        width: calc(100% + 10px);
        height: calc(100% + 10px);
        content: '';
        border: 3px solid $tertiary;
        border-radius: 50px;
      }
    }
  }

  &:disabled {
    cursor: not-allowed;
  }
}

.gray {
  align-items: center;
  padding: 8px 21px;
  font-size: 16px;
  color: #201150;
  cursor: pointer;
  background-color: #fcf1ff;
  border: 1px solid #dccfed;
  border-radius: 8px;

  &:active {
    transform: scale(1.05);
  }
}

.pink {
  @extend .button;

  padding: 10px;
  font-size: 16px;
  font-weight: 300;
  background-color: $background-alt;
  border-radius: 8px;

  @include media('md') {
    font-size: 18px;
  }

  &:hover {
    background-color: #e18cf6;
  }
}
