@import '@styles/theme/theme';

.wrapper {
  display: flex;
  width: 100%;
  height: fit-content;
  padding: 4px 15px;
  font-family: $content-font;

  &[data-julie-face='true']::before {
    width: 30px;
    margin-right: -30px;
    content: '';
    background-image: url('../../../../public/images/julie/julie_avatar.png');
    background-repeat: no-repeat;
    background-size: 30px;
  }

  &[data-message-type='user'] {
    justify-content: flex-end;
  }

  &[data-message-type='apply'] {
    justify-content: center;
  }

  .julie,
  .cv,
  .user {
    max-width: 430px;
    height: fit-content;
    padding: 15px;
    overflow: visible;
    border-radius: 6px;

    @include bp-mediumLarge() {
      max-width: 280px;
    }

    @include bp-smaller() {
      max-width: 200px;
    }
  }

  .julie {
    margin-left: 35px;
    background-color: white;
  }

  .cv {
    width: 100%;
    padding: 0;
    margin-left: 35px;
  }

  .user {
    align-self: flex-end;
    background-color: $tertiary;

    .edit {
      margin-left: 10px;
      color: $secondary;
      text-align: right;
      cursor: pointer;
    }
  }

  .apply {
    margin-top: 70px;
    border-radius: 50px;
  }

  h3 {
    margin-bottom: 15px;
    font-size: 18px;
    font-weight: 500;
    line-height: 23px;
    color: $primary;
  }

  h4 {
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
  }

  input,
  textarea {
    font-size: 15px;
  }

  ol,
  ul {
    margin: 5px auto 5px 18px;

    li {
      margin: 5px auto;
    }
  }

  ul {
    list-style-type: disc;
  }

  * {
    font-size: 14px;
    line-height: 18px;

    a {
      font-size: 13px;
      line-height: 16px;

      &:not([role='option']) {
        @include link();
      }
    }
  }
}
