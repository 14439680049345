@import '@styles/theme/theme';

.overlay {
  position: fixed;
  bottom: 0;
  z-index: 1000;
  width: 100%;
  height: auto;
  padding: 20px;
  margin: 0;
  pointer-events: none;
  background-color: transparent;
}

.cookies-popup {
  position: fixed;
  right: 20px;
  bottom: 0;
  bottom: 20px;
  left: 20px;
  z-index: 1000;
  display: block;
  padding: 15px 20px;
  color: #fff;
  pointer-events: all;
  background-color: $primary;
  border: 3px solid $background-alt;
  border-radius: 16px;

  @include media('sm') {
    max-width: 510px;
    padding: 15px 0 15px 20px;
  }

  &:focus {
    outline: none;
  }

  .link {
    display: none;
    width: fit-content;
    padding: 10px;
    margin-left: -10px;
    font-size: 17px;
    color: #fff;
    text-decoration: underline;
    cursor: pointer;

    @include media('sm') {
      display: block;
    }
  }

  .header {
    display: flex;

    img {
      height: 40px;
      margin: 0 10px;
    }

    p {
      padding: 0 10px;
      font-size: 19px;

      @include media('sm') {
        padding: 10px;
      }
    }

    .read-more {
      display: block;
      padding: 8px 0;
      margin-left: 10px;
      color: white;
      text-decoration: underline;

      @include media('sm') {
        display: none;
      }
    }
  }

  .content {
    display: none;
    padding: 10px;
    margin-left: -10px;
    font-size: 17px;

    @include media('sm') {
      display: block;
    }
  }

  .btn-container {
    position: relative;
    display: flex;
    gap: 10px;
    justify-content: center;
    height: 40px;
    margin: 20px auto 10px;

    button {
      width: 100%;
      padding: 10px 20px;

      @include media('sm') {
        width: 90px;
      }
    }

    .reject {
      @include reset-button();

      width: 100%;
      height: 48px;
      color: white;
      text-align: center;
      cursor: pointer;
      background-color: transparent;
      border: solid $secondary 1px;
      border-radius: 30px;

      @include media('sm') {
        width: 90px;
        height: 100%;
      }
    }

    .accept,
    .reject {
      height: 48px;

      @include media('sm') {
        height: 100%;
      }
    }
  }
}
