@import '@styles/theme/theme';

.label {
  display: flex;
  margin: 6px 12px;

  .icon {
    margin-right: 10px;
  }

  p {
    font-size: 16px;
    color: $blog-gray;
  }

  .loading {
    position: relative;
    top: -5px;
    display: inline-flex;
    width: 30px;
    font-size: 0;
    line-height: 18px;
    border-radius: 4px;

    @include skeleton();
  }
}
