@import '@styles/theme/theme';

.container {
  min-height: 80px;
}

ul.pagination-container {
  padding-right: 15px;
  padding-left: 15px;
  margin-top: 30px;
  margin-bottom: 30px;
  text-align: center;
}

@include bp-small() {
  ul.pagination-container {
    padding-right: 0;
    padding-left: 0;
  }
}

.pagination-container {
  li {
    display: inline-block;
  }

  a {
    display: inline-block;
    width: 40px;
    height: 40px;
    padding-top: 9px;
    margin-inline: 5px;
    font-family: $content-font;
    font-weight: 600;
    color: $primary;
    text-align: center;
    cursor: pointer;
    border-radius: 10px;
    opacity: 1;
  }
}

.pagination-active a {
  color: #fff !important;
  cursor: default;
  background: #f25aff 0% 0% no-repeat padding-box;
  opacity: 1;
}

.pagination-disabled a:hover {
  cursor: default;
  background: transparent !important;
}

.pagination-container a:hover {
  color: $primary !important;
  background: $tertiary 0% 0% no-repeat padding-box;
}

.pagination-active a:hover {
  color: #fff !important;
  background: #f25aff 0% 0% no-repeat padding-box;
  opacity: 1;
}
