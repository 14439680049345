@import '@styles/theme/theme';

.create-cv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 450px;
  margin-top: 25px;
  color: $primary;
  text-align: center;

  p {
    margin-bottom: 15px;
  }

  button {
    padding: 15px 20px;
    font-size: 16px;
    text-decoration: none;
    cursor: pointer;
    background-color: $background-alt;
    border: none;
    border-radius: 10px;
    outline: none;
  }
}

@include bp-mediumLarge() {
  .create-cv {
    width: 250px;
  }
}

@include bp-small() {
  .create-cv {
    width: 60vw;
  }
}

@include bp-smaller() {
  .create-cv {
    width: 90%;
  }
}
