@import '@styles/theme/theme';

.search-result {
  padding: 15px 20px;
  margin: 20px 0;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: $box-shadow;
  transition: all 250ms linear;

  &.compact {
    margin: 10px 0;
  }

  .early-apply {
    display: flex;
    align-items: center;
    padding: 15px 20px;
    margin: -15px -20px;
    margin-bottom: 15px;
    background-color: $background;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    p {
      margin-left: 5px;
      font-size: 13px;
    }
  }

  .job-details {
    .header {
      position: relative;
      z-index: $layer2;
      // height: 90px;

      .title,
      .salary,
      .company {
        margin-top: 15px;
        margin-bottom: 15px;
      }

      .title {
        width: 90%;
        margin-top: 5px;

        h2 {
          font-family: var(--mulish-font);
          font-size: 17px;
          font-weight: 600;
        }
      }

      .salary {
        width: 90%;

        p {
          font-size: 15px;
          font-weight: 600;
        }
      }

      .company {
        width: 90%;

        p {
          font-size: 13px;
          font-style: italic;
          color: #333;
        }
      }

      .company-pic {
        float: right;
        border-radius: 100%;
        object-fit: cover;

        img {
          border-radius: 100%;
        }
      }
    }

    .job-card-description {
      max-height: 45px;
      margin: 15px 0 20px;
      overflow: hidden;
      font-size: 17px;
    }

    .info {
      display: flex;
      justify-content: space-between;

      .location {
        z-index: $ground-layer;
        display: flex;
        align-items: center;

        p {
          margin-left: 5px;
          font-size: 13px;
        }
      }

      .date-posted {
        float: right;

        p {
          font-size: 13px;
          text-align: right;
        }
      }
    }
  }

  .option-message-hr {
    margin-top: 25px;
    margin-bottom: -1px;
    border: solid 0.5px rgb(0 0 0 / 10%);
  }

  .option-message {
    margin: 0 -20px;
    margin-bottom: -15px;
    font-weight: 600;
    text-align: center;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;

    .grid {
      display: grid;
      grid-template-columns: 35% 65%;
    }

    .one-tap-apply {
      padding: 20px 30px;
      color: #fff;
      background-color: $secondary;
      border-bottom-right-radius: 10px;
      transition: all 100ms linear;

      &:hover {
        background-color: $primary;
      }
    }

    .save {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 15px 30px;
      cursor: pointer;
      border-bottom-left-radius: 10px;

      p {
        margin-right: 10px;
        font-size: 16px;
      }
    }

    .applied {
      padding: 20px 30px;
      text-align: center;
      border-bottom-right-radius: 10px;

      &:hover {
        cursor: not-allowed;
      }
    }
  }
}

.highlight {
  padding: 10px;
  background-color: $secondary;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  img {
    display: inline-block;
    height: 24px;
    margin-right: 5px;
  }

  p {
    display: inline-block;
    font-size: 13px;
  }

  .highlighted-paragraph {
    margin-bottom: 15px;
    font-weight: 500;
    color: #fff;
  }

  .job-details {
    padding: 15px 20px;
    background-color: $quaternary;

    .header {
      img {
        position: relative;
        top: -100px;
        left: 675px;
        height: 40px;
        border-radius: 100%;
      }
    }
  }
}

@include bp-medium() {
  .search-result {
    .option-message {
      .grid {
        grid-template-columns: 1fr;

        .one-tap-apply {
          padding: 20px 10px;
          border-bottom-right-radius: 10px;
          border-bottom-left-radius: 10px;
        }

        .save {
          padding: 20px 10px;
          border-bottom-right-radius: 10px;
          border-bottom-left-radius: 10px;
        }
      }
    }
  }
}

@include bp-small() {
  .search-result .job-details .header {
    height: 100%;

    .title,
    .company {
      width: 80%;
    }
  }
}
