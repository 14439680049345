@import '@styles/theme/theme';

.title {
  position: relative;
  z-index: 10;
  font-size: 50px;
  line-height: 55px;

  @include media('sm') {
    font-size: 70px;
    line-height: 75px;
  }
}

.subtitle {
  margin-top: 30px;
  color: $soft-blue;
}

.heart {
  position: absolute;
  z-index: 0;
  margin-left: 220px;

  @include media('sm') {
    margin-left: 300px;
  }
}
