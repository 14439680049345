@import '@styles/theme/theme';

.toggle {
  span {
    padding: 5px 15px;
    margin-right: 15px;
    background-color: #fff;
    border: 1px solid $tertiary;
    border-radius: 15px;
    transition: all 100ms linear;

    &:hover {
      cursor: pointer;
      background-color: $tertiary;
    }
  }
}

.selected {
  background-color: $tertiary !important;

  &:hover {
    cursor: default !important;
    background-color: $quaternary !important;
  }
}

@include bp-medium() {
  .toggle {
    span {
      display: block;
      width: fit-content;
      padding: 10px 25px;
      margin-bottom: 15px;
    }
  }
}
