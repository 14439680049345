@import '@styles/theme/theme';

.comment {
  width: 100%;
  height: fit-content;
  padding: 20px;
  margin: 20px auto;
  background-color: #faecfe;
  border-radius: 16px;

  .header {
    display: flex;
    justify-content: space-between;

    .username {
      font-size: 18px;
      font-weight: bolder;

      @include media('md') {
        font-size: 20px;
      }
    }

    span {
      font-size: 18px;
      color: $blog-gray;

      @include media('md') {
        font-size: 20px;
      }
    }
  }

  .body {
    margin-top: 10px;
    font-size: 18px;

    @include media('md') {
      margin-top: 20px;
      font-size: 20px;
    }
  }
}
