@import '@styles/theme/theme';

.archived {
  &:hover {
    cursor: not-allowed !important;
  }

  .job-details,
  .application-info {
    filter: grayscale(0.5) !important;
  }

  .company,
  img,
  .desc,
  .info,
  .application-info {
    filter: opacity(0.7) !important;
  }
}

.application-item {
  padding: 15px 20px;
  margin: 25px 0;
  cursor: pointer;
  background-color: #fff;
  border-radius: 10px;
  transition: all 250ms linear;

  .application-info {
    p {
      margin-bottom: 10px;
      font-size: 17px;
    }

    hr {
      margin: 15px 0;
    }
  }

  .job-details {
    .desc {
      margin-top: 15px;
      margin-bottom: 20px;

      p {
        max-height: 45px;
        overflow: hidden;
        font-size: 17px;
      }
    }

    .info {
      display: flex;
      justify-content: space-between;

      .location {
        display: flex;
        align-items: center;

        p {
          margin-left: 5px;
          font-size: 13px;
        }
      }

      .date-posted {
        float: right;

        p {
          font-size: 13px;
          text-align: right;
        }
      }
    }

    .header {
      height: 90px;

      .title,
      .salary,
      .company {
        margin-top: 15px;
        margin-bottom: 15px;
      }

      .title {
        width: 90%;
        margin-top: 5px;

        p {
          font-size: 17px;
          font-weight: 600;
        }
      }

      .salary {
        width: 90%;

        p {
          font-size: 15px;
          font-weight: 600;
        }
      }

      .company {
        width: 90%;

        p {
          font-size: 13px;
          font-style: italic;
          color: #333;
        }
      }

      .company-pic-wrapper {
        position: relative;
        top: -90px;
        float: right;
        width: 40px;
        height: 40px;

        .company-pic {
          position: relative;
          top: -75px;
          left: 695px;
          width: 40px;
          height: 40px;
          border-radius: 100%;
          object-fit: cover;

          img {
            border-radius: 100%;
          }
        }
      }
    }
  }

  .employer-message {
    padding: 25px 30px;
    margin: 0 -20px;
    margin-top: 15px;
    margin-bottom: -15px;
    font-weight: 700;
    color: #fff;
    text-align: center;
    background-color: $secondary;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    transition: all 100ms linear;

    &:hover {
      cursor: pointer;
      background-color: $primary;
    }
  }

  .employer-message-answered {
    padding: 25px 30px;
    margin: 0 -20px;
    margin-top: 15px;
    margin-bottom: -15px;
    font-weight: 700;
    color: #fff;
    text-align: center;
    background-color: $quaternary;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    transition: all 100ms linear;

    &:hover {
      cursor: not-allowed;
    }
  }

  .feedback-message-hr {
    margin-top: 25px;
    margin-bottom: -1px;
    border: solid 0.5px rgb(0 0 0 / 10%);
  }

  .feedback-message {
    margin: 0 -20px;
    margin-bottom: -15px;
    font-weight: 600;
    text-align: center;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;

    .grid {
      display: grid;
      grid-template-columns: 35% 65%;

      .up {
        padding: 20px 30px;
        color: #fff;
        background-color: $secondary;
        border-bottom-right-radius: 10px;
        transition: all 100ms linear;

        &:hover {
          background-color: $primary;
        }
      }

      .down {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 30px;
        border-bottom-left-radius: 10px;

        img {
          width: 40px;
        }
      }
    }

    .approved-message {
      padding: 20px 30px;
      color: #fff;
      cursor: not-allowed;
      background-color: #34c759;
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    .disapproved-message {
      padding: 20px 30px;
      color: #fff;
      cursor: not-allowed;
      background-color: #93939b;
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
    }
  }
}

@include bp-medium() {
  .application-item {
    .feedback-message {
      .grid {
        grid-template-columns: 1fr;

        .up {
          border-bottom-right-radius: 10px;
          border-bottom-left-radius: 10px;
        }

        .down {
          padding: 10px;
          border-bottom-right-radius: 10px;
          border-bottom-left-radius: 10px;
        }
      }
    }
  }
}

@include bp-small() {
  .application-item .job-details .header {
    height: 100%;

    .title,
    .salary,
    .company {
      width: 80%;
    }

    .company-pic-wrapper {
      top: auto;
      margin-top: -90px;

      .company-pic {
        left: 65vw;
      }
    }
  }
}
