@import '@styles/theme/theme';

.info {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  font-size: 18px;
  color: $blog-gray;
  list-style-type: none;

  .dot {
    display: none;
  }

  @include media('sm') {
    justify-content: center;

    .dot {
      display: block;
    }
  }

  @include media('md') {
    font-size: 20px;
  }

  li,
  .categories {
    width: 100%;
    margin: 10px;
    text-align: center;

    @include media('sm') {
      width: unset;
    }

    .category {
      width: fit-content;
      padding: 6px 10px;
      margin-inline: 5px;
      margin-top: 10px;
      color: $primary;
      word-wrap: normal;
      background-color: $background-alt;
      border-radius: 8px;

      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

  .categories {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: -5px;

    @include media('sm') {
      display: inline-flex;
      margin-bottom: unset;
    }
  }
}
