@import '@styles/theme/theme';

.cover {
  position: relative;
  display: block;
  max-width: 900px;
  height: 300px;
  margin: 30px auto 0;
  overflow: hidden;
  border-radius: 24px;

  @include media('md') {
    height: 500px;
    margin: 40px auto 0;
  }

  img {
    object-fit: cover;
  }
}
