@import '@styles/theme/theme';

/* choice-widget */

.choice-widget-personal-info {
  .option {
    color: $secondary;
    text-decoration: underline;
    transition: all 100ms linear;

    p {
      padding: 10px 0;
      font-size: 14px;
    }

    &:hover {
      font-weight: 600;
      cursor: pointer;
    }
  }

  hr {
    color: $primary;
  }
}
