@import '@styles/theme/theme';

.share {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0 0 10px;
  background-color: white;
  border-radius: 8px;

  p {
    display: inline;
    margin-top: -3px;
    font-size: 18px;
  }

  button {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    margin-left: 10px;
    background-color: #f4d4fb !important;
    border: solid #f4d4fb 2px !important;
    border-radius: 8px;
    transition: 0.3s;

    &:hover {
      background-color: transparent !important;
    }
  }
}
