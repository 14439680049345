@import '@styles/theme/theme';

.related-blogs {
  @include page-container();

  margin-top: 50px;
  margin-right: auto;
  margin-left: auto;
  border-bottom-right-radius: 25px;
  border-bottom-left-radius: 25px;

  h2 {
    // max-width: 800px;
    margin: 0 auto 30px;
    // font-family: $title-font;
    // font-size: 42px;
    font-style: normal;
    font-weight: 600;
    font-stretch: normal;
    color: $secondary;
    text-align: center;
  }
}

// @include bp-small() {
//   .related-blogs {
//     h2 {
//       font-size: 32px;
//     }
//   }
// }
