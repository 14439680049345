@import '@styles/theme/theme';

.applications-list {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  margin-top: 25px;

  h5 {
    margin-left: 5px;
  }
}
