@import '@styles/theme/theme';

.root {
  .links {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-top: 20px;
    line-height: 1.5;

    .li {
      width: 100%;
    }

    .link {
      font-family: $content-font;
      font-size: 17px;
      font-weight: 600;

      .underline {
        text-decoration: underline;
        text-decoration-thickness: 2px;
        text-decoration-color: $primary;
        text-underline-offset: 2px;
        transition: text-decoration-color 0.3s ease-in-out;
      }

      &:hover {
        .underline {
          text-decoration-color: $secondary;
        }
      }
    }
  }
}
