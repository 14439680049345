$color: #d546f8;
$linkSize: 40px;

.pagination {
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;
  font-weight: 500;
}

.icon {
  width: 20px;
  height: 20px;
}

.inner {
  display: flex;
  gap: 2px;
  align-items: center;
  justify-content: center;
}
