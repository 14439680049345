@import '@styles/theme/theme';

.preview-message {
  input {
    box-sizing: border-box;
    display: block;
    max-width: 100%;
    height: 40px;
    padding: 0 15px;
    margin-top: 10px;
    margin-bottom: 0;
    color: $primary;
    background-color: $background;
    border: 1px solid #ccc;
    border-radius: 10px;
    outline: none;
  }

  p {
    font-size: 14px;
  }

  .qualifications-wrapper {
    max-width: 100%;
    margin-top: 10px;
  }

  .date-message-wrapper input {
    width: 160px !important;
  }

  .delete {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    float: right;
    width: fit-content;
    margin-top: 10px;
    margin-bottom: 10px;
    color: $alert-chat;
    text-decoration: underline;
    cursor: pointer;
    border: none;
    outline: none;
  }

  .done {
    box-sizing: border-box;
    display: inline-block;
    grid-column: 1 / 3;
    width: fit-content;
    padding: 10px 25px;
    margin-top: 8px;
    margin-right: 10px;
    color: #fff;
    background-color: $secondary;
    border: none;
    border-radius: 25px;
    outline: none;
    transition: all 100ms linear;

    &:hover {
      cursor: pointer;
      background-color: $primary;
    }
  }

  .disabled {
    background-color: $quaternary !important;

    &:hover {
      background-color: $quaternary !important;
    }
  }

  .trash {
    width: 25px;
    margin: 0 10px;
  }
}

.date-picker-space-fix {
  height: 250px;
}

@include bp-small() {
  .preview-message input {
    width: 65vw !important;
  }

  .qualifications-wrapper {
    width: 65vw;
  }

  .date-message-wrapper {
    input {
      width: 160px !important;
    }
  }

  .date-picker-space-fix {
    height: 350px;
  }
}
