@import '@styles/theme/theme';

.job-quick-info {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
  margin-bottom: 20px;

  .item {
    display: flex;
    align-items: center;

    &:first-child {
      margin-left: 0;
    }

    p {
      margin-left: 5px;
    }
  }
}
