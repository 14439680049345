@import '@styles/theme/theme';

.summary {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 15px;
  line-height: 1.4;
  background-color: $background;
  border-radius: 16px;

  @include media('sm') {
    padding: 20px;
  }
}

.header {
  display: flex;
  gap: 10px;
  align-items: center;
}

.tooltip {
  margin-top: 6px;
}

.title {
  font-family: $title-font;
  font-weight: 600;
  color: $primary;
  opacity: 0.8;
}

.content {
  font-size: 15px;
  transition: max-height 4s ease;

  @include media('sm') {
    font-size: 18px;
  }

  ul {
    margin: 0 0 10px 20px;
    white-space: break-spaces;
    list-style-type: disc;

    li {
      width: 100%;
      color: $primary;
      text-align: left;
      word-break: break-word;
      opacity: 0.8;
    }
  }
}
