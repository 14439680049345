@import '@styles/theme/theme';

.footer {
  display: inline-block;
  width: 100%;
  margin-top: 20px;

  @include media('ms') {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    margin-top: 50px;
  }

  h3 {
    width: 100%;
    margin-top: 20px;
    font-size: 18px;
    color: white;
    text-align: center;

    @include media('ms') {
      width: fit-content;
      margin-top: unset;
      text-align: left;
    }
  }

  .social {
    h3 {
      margin-bottom: -20px;
    }
  }

  .apps-download {
    margin-bottom: 30px;

    @include media('ms') {
      margin-bottom: 0;
    }

    .download-btns {
      display: flex;
      justify-content: center;
      margin: 15px 0 0 -10px;

      @include media('ms') {
        justify-content: flex-start;
      }

      button {
        background-color: $tertiary;
        border: none;

        @include media('ms') {
          // Import since overriding download app menu
          width: 50px !important;
          height: 50px !important;
        }

        img {
          @include media('ms') {
            height: 25px !important;
          }
        }
      }
    }
  }

  .country-selector {
    position: relative;
    bottom: 20px;
    left: 50%;
    width: fit-content;
    transform: translateX(-50%);

    @include media('ms') {
      bottom: unset;
      left: 0;
      transform: unset;
    }

    h3 {
      position: relative;
      margin-bottom: 20px;

      @include media('ms') {
        margin-bottom: 10px;
      }
    }

    .selector {
      h3 {
        width: 0;
        margin: 0;
      }

      @include media('ms') {
        margin-top: 10px;
      }
    }
  }
}
