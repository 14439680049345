@import '@styles/theme/theme';

.link {
  display: none;
  width: fit-content;
  height: fit-content;
  margin-top: 7px;
  margin-right: 10px;
  font-size: 18px;
  color: white;

  @include media('ms') {
    display: block;
  }
}
