@import '@styles/theme/theme';

.container {
  @include page-container();
  @include container-padding();

  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 20px 0;
}

.research-title {
  font-family: $title-font;
  font-size: 60px;
  font-style: normal;
  font-weight: 600;
  font-stretch: normal;
  color: $secondary;
  text-align: center;

  @include bp-medium() {
    font-size: 42px;
  }

  @include bp-small() {
    font-size: 32px;
  }
}
