@import '@styles/theme/theme';

.main-container {
  @include page-container();
  @include container-padding();

  margin: 0 auto;

  .start {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: fit-content;
    margin-top: 250px;

    .title {
      display: flex;
      align-items: center;
      justify-content: center;

      h1 {
        margin-right: 10px;

        @include bp-mediumLarge() {
          font-size: 42px;
        }

        @include bp-medium() {
          font-size: 24px;
        }
      }
    }
  }

  a {
    margin-top: 30px;
    line-height: 30px;
    color: white;
  }
}
