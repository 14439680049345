@import '@styles/theme/theme';

.responsibility-disabled {
  background-color: $quaternary !important;

  &:hover {
    background-color: $quaternary !important;
  }
}

.responsibility {
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  width: fit-content;
  padding: 12px 15px;
  font-weight: bold;
  color: #fff;
  cursor: pointer;
  background-color: $primary;
  border: none;
  border-radius: 8px;
  outline: none;

  .content {
    word-break: break-word;
  }
}

.delete-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15px;
  min-width: 15px;
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
}
