@import '@styles/theme/theme';

.hero {
  @include container-padding();
  @include page-container();

  position: relative;
  display: block;
  margin-top: 150px;
  overflow: hidden;

  @include media('ml') {
    display: flex;
    justify-content: space-between;
  }

  .info {
    position: relative;
    width: 100%;

    @include media('sm') {
      padding-right: 20px;
    }
  }
}

.divider {
  @include container-padding();
  @include page-container();

  position: relative;
  box-sizing: border-box;
  display: block;
  width: 100%;
  margin-top: 60px;
}
