@import '@styles/theme/theme';

.wrap {
  @include container-padding();

  position: relative;
  width: 100%;
  height: auto;
  margin: 20px auto 0;
}

.img {
  position: absolute;
  bottom: 0;
  left: -10px;
  z-index: 100;
  width: 70px;

  @include media('sm') {
    bottom: 17px;
    left: -5px;
    width: 78px;
  }
}

.input-container {
  position: relative;
  z-index: 150;
  width: 100%;
  height: 50px;
  margin: 0 auto;

  @include media('sm') {
    width: 100%;
  }

  @include media('md') {
    width: 600px;
    height: 80px;
  }

  input {
    z-index: 100;
    width: 100%;
    height: 50px;
    padding: 0;
    padding-left: 60px; /* using background to make user believe there is an icon there - only way for inputs */
    font-size: 19px;
    border: solid 1px rgb(32 17 80 / 10%);
    border-radius: 50px;
    box-shadow: 0 12px 20px 0 rgb(0 0 0 / 10%);

    @include media('sm') {
      padding-left: 100px;
      font-size: 27px;
    }

    @include media('md') {
      height: 70px;
    }

    &:focus {
      outline: none;
    }
  }
}
