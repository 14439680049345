.valid {
  color: #4dc048;
  list-style: url('./valid.svg');
}

.chat-specs {
  margin-top: 10px;

  li {
    margin-left: 20px;
    list-style-type: disc;
  }
}
