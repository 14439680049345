@import '@styles/theme/theme';

.btn {
  width: fit-content !important;
  min-width: fit-content !important;
  max-width: fit-content !important;
  margin: 5px;
}

.selected {
  background-color: $secondary;
}
