@import '@styles/theme/theme';

.background {
  position: relative;
  width: 100%;

  .footer {
    width: 100%;
    height: 100%;
    min-height: 746px;
    overflow: hidden;
    background-color: $primary;
    border-radius: 24px 24px 0 0;

    @media only screen and (width <= 560px) {
      min-height: 946px;
    }

    .container {
      @include container-padding();

      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 10;
      width: fit-content;
      height: fit-content;
      transform: translate(-50%, -50%);

      @media only screen and (width <= 560px) {
        margin-top: 20px;
      }

      .image-container {
        text-align: center;
      }

      .middle-img {
        position: static;
        max-width: 396px;
        margin-top: 10px !important;
        overflow: hidden;
        transform: rotate(15deg);

        @include media('sm') {
          display: none;
        }
      }

      p {
        margin-top: 60px;
        font-size: 18px;
        color: white;
        text-align: center;
      }

      .outro {
        margin: 0 auto;
        font-size: 14px;
        color: white;
        text-align: center;
      }

      .terms {
        position: relative;
        display: flex;
        width: fit-content;
        margin: 30px auto 0;
        font-size: 14px;
        color: white;
        text-align: center;

        a {
          color: white;
        }

        @include bp-small() {
          margin-bottom: 80px;
        }
      }
    }

    .background-container {
      position: relative;
      top: 100px;
      z-index: 0;
      max-width: 1700px;
      margin: 0 auto;
      pointer-events: none;
      background-repeat: no-repeat;
      background-size: contain;

      @media only screen and (width <= 1200px) {
        top: 190px;
      }

      @media only screen and (width <= 1024px) {
        top: 100px;
      }

      @include media('sm') {
        aspect-ratio: 827/1063;
        background-image: var(--footer-bg-tablet);
      }

      @include media('ml') {
        aspect-ratio: 1520/577;
        background-image: var(--footer-bg-desktop);
      }
    }
  }
}

.title {
  @include home-title();

  margin-top: 20px;
  font-weight: 600;
  color: $secondary;
}

.subtitle {
  @include home-subtitle();

  color: white;
}
