@import '@styles/theme/theme';

.typeahead-item {
  height: fit-content;
  padding: 10px;
  margin: 10px;
  font-weight: normal;
  color: $primary;
  text-align: left;
  letter-spacing: 0;
  border-radius: 6px;
}

.typeahead {
  input {
    font-family: $content-font;

    &:disabled {
      cursor: not-allowed;
    }
  }
}

div.typeahead-item:hover {
  background: $background 0% 0% no-repeat padding-box;
}

.typeahead-item-active {
  background: $background 0% 0% no-repeat padding-box !important;
}

.typeahead-item-menu {
  @include scrollbar();

  z-index: 200;
  margin-top: 10px;
  overflow-y: scroll;
  background: #fff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  box-shadow: 0 6px 12px #15181c1a;

  &::-webkit-scrollbar-track {
    overflow: hidden;
    background: #fff;
    border-radius: 0 10px 10px 0;
  }
}

.item-no-match,
.item-searching,
.item-prompt {
  display: none;
}

.typeahead-error input[type='text'] {
  background-color: $background-error !important;
  border: 3px solid $error !important;

  &::placeholder {
    color: $error !important;
    opacity: 1;
  }
}

.error {
  margin: 10px 0 0 10px;
  color: $error;
}

.menu-title {
  margin: 15px 20px;
  font-family: $title-font;
  font-weight: 600;
  color: $secondary;
}
