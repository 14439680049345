@import '@styles/theme/theme';

.wrapper {
  @include container-padding();

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100svh;

  p {
    text-align: center;
  }
}
