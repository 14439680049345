@import '@styles/theme/theme';

.custom {
  margin-top: 20px;
  font-size: 12px;

  a {
    text-decoration: underline;
  }
}

.generic {
  margin-top: 15px;
  font-size: 12px;
}

.not-eligible {
  margin-top: 20px;
  font-size: 16px !important;
  color: $alert-chat;
}
