@import '@styles/theme/theme';

.field {
  grid-column: 1 / 3;
  max-width: 300px;
  margin: 10px 0;

  label {
    font-weight: bold;
  }

  input {
    display: block;
    width: 100%;
    height: 40px;
    padding: 0 15px;
    margin: 5px auto;
    color: $primary;
    background-color: $background;
    border: 1px solid #ccc;
    border-radius: 10px;
    outline: none;
  }
}
