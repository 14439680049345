@import '@styles/theme/theme';

@keyframes spin {
  0% {
    transform: translateX(0%) translateY(0%) rotate(0deg);
  }

  100% {
    transform: translateX(0%) translateY(0%) rotate(360deg);
  }
}

.password-specs {
  padding-left: 20px;
  margin: 10px 0;
  list-style: disc;

  p {
    margin-left: -20px;
  }

  .valid {
    color: #79d475;
    list-style: url('./valid.svg');
  }
}

.remove-arrows {
  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    margin: 0;
    appearance: none;
  }

  /* Firefox */
  &[type='number'] {
    appearance: textfield;
  }
}

.congrats-btn {
  position: relative;
  display: block;
  margin: 30px auto;
  // Override message base link
  color: white !important;
  text-decoration: none !important;
}

.forgot {
  display: block !important;
  padding: 10px 0 !important;
  font-weight: 400 !important;
  color: $primary !important;
  text-decoration: underline !important;
  background-color: transparent !important;

  &:disabled,
  &:hover {
    background-color: transparent !important;
  }
}

.forgot-prompt {
  margin-bottom: 10px;
  font-weight: 600;
}

.loading-signup {
  position: fixed;
  top: 0;
  z-index: 1001;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 610px;
  height: 100%;
  font-size: 24px;
  background-color: rgb(207 205 207 / 60%);
  border-radius: 25px 0 0 25px;
  transition: all 100ms linear;

  @include bp-mediumLarge() {
    width: 425px;
  }

  @include bp-small() {
    width: 100%;
  }

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 20px;
    background-color: #fff;
    border-radius: 7px;

    .spinner {
      padding: 15px;
      border: 3px solid $secondary;
      border-top: 3px solid transparent;
      border-radius: 50%;
      transform: translate(-50%, -50%);
      animation: spin 750ms 0ms ease-in-out infinite;
    }

    strong {
      margin-left: 35px;
    }
  }
}

.email {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  padding: 10px;
  margin-top: 10px;
  font-size: 14px;
  background-color: $background;
  border: 1px solid rgba(32 17 80 / 10%);
  border-radius: 10px;
}

.error {
  margin-top: 10px;
  font-size: 13px;
  color: $error;
}

.button {
  margin: 10px 0;
  font-weight: 500;
}
