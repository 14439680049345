@import '@styles/theme/theme';

.map-container {
  position: relative;
  display: block;
  width: 100%;
  max-height: 300px;
  aspect-ratio: 640/300;

  @include media('sm') {
    aspect-ratio: 1/1;
  }

  img {
    width: 100%;
    border-radius: 15px;
  }
}
